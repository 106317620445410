
<template>
  <div class="bg">
    <div class="wrapper">
      <div class="box">
        <a-form
          ref="loginFromDom"
          :model="formValue"
          :style="{width:'550px',height:'393px'}"
          @submit="submit"
          class="login-form"
          :rules="rules"
        >
          <a-space class="title">
            密码登录
          </a-space>
          <a-form-item field="userName" hide-label :validate-trigger="['change','input']">
            <a-input v-model="formValue.userName" placeholder="请输入手机号" />
          </a-form-item>
          <a-form-item field="_password" hide-label :validate-trigger="['change','input']">
            <a-input-password v-model="formValue._password" placeholder="请输入密码" />
          </a-form-item>
          <div class="log">
            <!-- <a-checkbox v-model="formValue.isRead">
              3天内自动登录
            </a-checkbox> -->
            <span style="float:right;cursor: pointer;" @click="reset">忘记密码?</span>
          </div>
          <a-space>
            <a-button class="btn-foot" :loading="loading" html-type="submit" style="background:#4F17A8;color:#fff">登录
            </a-button>
          </a-space>
          <!-- <a-space class="rou-log">没有账号,<span style="color:#207AFF;cursor: pointer;" @click="sign">去注册</span></a-space> -->
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.obj = from
      })
    }
  }
</script>

<script setup>
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as loginApi from '@/api/login'
import md5 from 'js-md5'
import { Message } from '@arco-design/web-vue'
import { useStore } from 'vuex'
const store = useStore();
const router = useRouter()
const route = useRoute()
const getFrom = () => {
  return {
    userName: '',
    _password: '',
    isRead: false,
    loginMethod: 'common',
    loginType: 'web',
    remeberMe: false,
    valCode: ''
  }
}
const formValue = ref(getFrom())
const getRules = () => {
  return {
    userName: [
      { required: true, message: '手机号不能为空！' },
      { match: /^1[3456789]\d{9}$/, message: '请输入有效手机号' },
    ],
    _password: [
      { required: true, message: '请输入密码' },
    ],
  }
}
const rules = ref(getRules())
const loading = ref(false)
const obj = ref()
const loginFromDom = ref();
const submit = async () => {
  loading.value = true;
  const errors = await loginFromDom.value?.validate();
  if (!errors) {
    loginApi.login({
      userName: formValue.value.userName,
      passWord: md5(formValue.value._password),
      isRead: formValue.value.isRead,
      loginMethod: formValue.value.loginMethod,
      loginType: formValue.value.loginType,
      remeberMe: formValue.value.remeberMe,
      valCode: formValue.value.valCode,
    }).then(res => {
      loading.value = false
      if (res.code == 20000) {
        Message.success('登录成功')
        if (res.msg) {
          Message.warning(res.msg);
        }
        window.localStorage.setItem('webtoken', res.result.token)
        window.localStorage.setItem('baseId', res.result.baseId)
        window.localStorage.setItem('webuserInfo', JSON.stringify(res.result.user))
        store.dispatch('saveUser', res.result.user);
        router.replace({ path: '/' });
        return;
        if (obj.value.href && obj.value.href != '/signup') {
          router.replace({ path: obj.value.path, query: obj.value.query })
        } else {
          router.replace({ path: '/' })
        }
        // let target = router.currentRoute.value.query
        // if (target.url) {
        //   router.replace({ path: decodeURIComponent(target.url), query: route.query })
        // } else {
        //   router.replace({ path: '/' })
        // }
      }
    })
  } else {
    loading.value = false;
  }
}
//忘记密码
const reset = () => {
  router.push({ path: '/password_reset' })
}
// 注册
const sign = () => {
  router.push({ path: '/signup' })
}
defineExpose({ obj })
</script>

<style lang="less" scoped>
.bg {
  width: 100%;

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-form {
    background: #FFFFFF;
    box-shadow: 0px 1px 8px 0px rgba(220, 220, 220, 0.5000);
    border-radius: 4px;
    padding: 40px 10px;
    box-sizing: border-box;
  }
}

:deep(.arco-form-item) {
  width: 300px;
}

:deep(.arco-form) {
  align-items: center;
}

.title {
  width: 300px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #323233;
  line-height: 37px;
  margin-bottom: 26px;
}

:deep(.arco-checkbox) {
  padding-left: 0;
}

.btn-foot {
  width: 300px;
  margin-top: 24px;
}

.rou-log {
  width: 300px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323233;
  line-height: 22px;
  margin-top: 20px;
  justify-content: flex-end;
}

.log {
  width: 300px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323233;
  line-height: 22px;
}
</style>
